export const textsAndConfigs = {
  aboutMeText: `Apaixonado por tecnologia e tudo oque ela pode fazer. 
    Sempre gostei de criar coisas desde criança e achei na programação um 
    jeito de fazer oque eu gosto de forma criativa e desafiadora.`,


  experiences: [
    {
      name: 'Scl sistemas',
      techs: 'Figma · Node.js · MongoDB · React Native · Expo · TypeScript · jQuery · Cascading Style Sheets (CSS) · JavaScript · HTML· Rust',
      started:'Junho 2022',
      end: 'Presente',
      text: `Desenvolvimento e manutenção de projetos como 
      TOTH-DIRECT, TOTHREP e Supervisor`
    },
  ],

  projects: [
    {
      name: "Fox Home",
      tech: "React styled-components Redux Dexie",
      description: `Uma página inicial personalizada para navegadores. 
      Com opções de trocar a cor e imagem, formato de 12 horas, 
      borrão de fundo, bordas arrendondadas e sites favoritos. 
      Atualmente disponivel para download na Chrome Web Store.`,
      github: "https://github.com/eidiinnn/Home-page",
      link: "https://chrome.google.com/webstore/detail/fox-home/kbalcggddakepkjgcpijccappaeppfmh",
    },

    {
      name: "Site Pessoal",
      tech: "React EmailJs Jest styled-components.",
      description: `Meu site pessoal, usado para mostrar um pouco sobre mim e meus projetos. Como opção
      de contato é usada a API do EmailJS para mandar as mensagens para minha caixa de
      entrada e também é responsivo deixando a navegação mais fácil nos celulares.`,
      github: "https://github.com/eidiinnn/Personal-website",
      link: " https://eduardosilva.online",
    },
  ],
};
